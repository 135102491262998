<template>
  <div>
    <div class="modal fade" id="modal-justificaciones">
      <div class="modal-dialog modal-md">
        <div class="modal-content">
          <div class="modal-header bg-frontera-top-left pt-2 pb-2">
            <h4 class="modal-title text-white">Novedad</h4>
            <button
              type="button"
              class="close text-white"
              data-dismiss="modal"
              aria-label="Close"
              id="close-modal"
              ref="closeModal"
              @click="limpiarModal()"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body col-md-12">
            <div class="row">
              <div class="col-md-2">
                <label for="tipo de novedad">Turno</label>
                <input
                  type="text"
                  class="form-control form-control-sm"
                  id="turno"
                  readonly
                  v-model="form.tif_turno_id"
                />
              </div>
              <div class="col-md-10">
                <div class="form-group">
                  <label for="tipo de novedad">Tipo de Novedad</label>
                  <select
                    class="form-control form-control-sm"
                    v-model="form.tipo"
                    :class="$v.form.tipo.$invalid ? 'is-invalid' : 'is-valid'"
                  >
                    <option value="">Seleccione...</option>
                    <option
                      v-for="novedad in listasForms.tipo_novedades"
                      :key="novedad.numeracion"
                      :value="novedad.numeracion"
                    >
                      {{ novedad.descripcion }}
                    </option>
                  </select>
                </div>
              </div>
              <div class="form-group">
                <label for="Observacion">Observacion</label>
                <textarea
                  v-model="form.observacion"
                  cols="51"
                  rows="5"
                  class="form-control form-control-sm"
                  :class="
                    $v.form.observacion.$invalid ? 'is-invalid' : 'is-valid'
                  "
                ></textarea>
                <div class="error" v-if="!$v.form.observacion.required">
                  Ingrese una observacion
                </div>
              </div>
            </div>
            <div class="row" v-if="form.id">
              <div class="col-md-4">
                <label for="link_fotografia">Foto (png, gif, jpg)</label>
                <div class="form-group" v-if="form.link_fotografia == null">
                  <input
                    type="file"
                    class="form-control-file"
                    accept="image/x-png,image/gif,image/jpeg"
                    @change="obtenerImagen"
                  />
                </div>
                <figure class="form-group" v-if="miniLogo">
                  <div class="button-container">
                    <img
                      :src="miniLogo"
                      alt="Logo"
                      width="236"
                      height="125"
                      class="rounded mx-auto d-block"
                    />
                    <a
                      @click="destroyImage()"
                      id="BotonEliminar"
                      v-if="form.link_fotografia"
                      href="#"
                    >
                      Eliminar
                      <i class="fas fa-window-close"></i>
                    </a>
                  </div>
                </figure>
                <div class="row">
                  <div
                    class="form-group col-md-6"
                    v-if="miniLogo && archivoValido"
                  >
                    <button
                      type="button"
                      class="btn btn-secondary"
                      @click="limpiarArchivo()"
                    >
                      <i class="fas fa-eraser"></i>
                    </button>
                  </div>
                  <div class="col-md-6" v-if="archivoValido">
                    <button
                      type="button"
                      @click="saveImage()"
                      class="btn btn-success"
                      data-toggle="tooltip"
                      data-html="true"
                      title="Guardar Imagen"
                    >
                      <i class="fa fa-upload"></i>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <div class="row">
              <button
                class="btn bg-primary"
                v-show="form.observacion && form.tipo"
                @click="save()"
              >
                <i class="fas fa-paper-plane"></i><br />Guardar
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import axios from "axios";
import $ from "jquery";
import { required, minLength } from "vuelidate/lib/validators";
export default {
  name: "TifJustificacionesModal",
  components: {},

  data() {
    return {
      cargando: false,
      novedades: {},
      miniLogo: null,
      archivoValido: false,
      fileFotografia: null,
      form: {
        observacion: null,
        tif_turno_id: null,
        tipo: null,
        link_fotografia: null,
      },
      listasForms: {
        tipo_novedades: [],
      },
      uri_docs: process.env.VUE_APP_AWS_BUCKET_URL,
    };
  },
  validations: {
    form: {
      observacion: {
        required,
      },
      tipo: {
        required,
      },
    },
  },

  methods: {
    llenarModal(turno) {
      if (turno.justificacion) {
        this.form = turno.justificacion;
        this.miniLogo = this.uri_docs + this.form.link_fotografia;
      } else {
        this.form.tif_turno_id = turno.tif_turno_id;
        this.form.tif_novedad_id = turno.id;
      }
    },

    getTipoNovedad() {
      axios.get("/api/lista/113").then((response) => {
        this.listasForms.tipo_novedades = response.data;
      });
    },

    limpiarArchivo() {
      this.form.link_fotografia = null;
      this.miniLogo = null;
      this.archivoValido = false;
      this.fileFotografia = null;
    },

    limpiarModal() {
      this.form.link_fotografia = null;
      this.miniLogo = null;
      this.archivoValido = false;
      this.fileFotografia = null;
      this.form = {};
    },

    save() {
      if (!this.$v.form.$invalid) {
        this.cargando = true;
        axios({
          method: "PUT",
          url: "/api/tif/justificaciones/save",
          data: this.form,
        })
          .then((response) => {
            this.form = response.data;
            this.$swal({
              icon: "success",
              title: "Se actualizó la justificacion correctamente...",
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 1000,
              timerProgressBar: true,
            });
            if (this.form.link_fotografia) {
              this.$refs.closeModal.click();
            }
            this.$parent.getIndex();
            this.cargando = false;
          })
          .catch((e) => {
            this.cargando = false;
            this.$swal({
              icon: "error",
              title: "Ocurrio un error, vuelva a intentarlo..." + e,
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 1000,
              timerProgressBar: true,
            });
          });
      }
    },

    saveImage() {
      this.cargando = true;
      let formData = new FormData();
      formData.append("id", this.form.id);
      formData.append("link_fotografia", this.fileFotografia);
      axios
        .post("api/tif/justificaciones/foto", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((response) => {
          this.$swal({
            icon: "success",
            title: "Los datos se guardaron exitosamente...",
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 1000,
            timerProgressBar: true,
          });
          this.archivoValido = false;
          this.form.link_fotografia = response.data.link_fotografia;
          this.cargando = false;
        })
        .catch((e) => {
          this.cargando = false;
          this.$swal({
            icon: "error",
            title: "Ocurrio un error" + e,
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 1000,
            timerProgressBar: true,
          });
        });
    },

    obtenerImagen(e) {
      this.miniLogo = null;
      this.archivoValido = false;
      if (e.target.files[0]) {
        this.cargando = true;
        const file = e.target.files[0];
        if (this.validaArchivo(file)) {
          this.archivoValido = true;
          this.fileFotografia = file;
          this.cargarImagen(file);
        }
      }
    },

    cargarImagen(file) {
      let reader = new FileReader();
      reader.onload = (e) => {
        this.miniLogo = e.target.result;
      };
      reader.readAsDataURL(file);
      this.cargando = false;
    },

    validaArchivo(file) {
      let flag = true;
      if (file.size > 2048000) {
        flag = false;
        this.fileFotografia = null;
        this.cargando = false;
        this.$swal({
          icon: "error",
          title: `El Archivo es muy pesado para subirlo`,
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 2000,
          timerProgressBar: true,
        });
      }
      return flag;
    },

    destroyImage() {
      this.$swal({
        title: "Esta seguro de eliminar esta Imagen?",
        text: "Los cambios no se pueden revertir!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Si, Eliminar!",
      }).then((result) => {
        if (result.value) {
          axios
            .delete("/api/tif/justificaciones/foto/" + this.form.id)
            .then((response) => {
              this.$swal({
                icon: "success",
                title: "La imagen se elimino correctamente...",
                toast: true,
                position: "top-end",
                showConfirmButton: false,
                timer: 1000,
                timerProgressBar: true,
              });
            })
            .catch(function(error) {
              this.$swal({
                icon: "error",
                title: "Ocurrio un error" + error,
                toast: true,
                position: "top-end",
                showConfirmButton: false,
                timer: 1000,
                timerProgressBar: true,
              });
            });
          this.miniLogo = null;
          this.form.link_fotografia = null;
        }
      });
    },
  },

  mounted() {
    this.cargando = true;
    this.getTipoNovedad();
    this.cargando = false;
  },
};
</script>
