<template>
  <div class="hold-transition">
    <loading v-if="cargando" />
    <div class="wrapper">
      <div class="content-wrapper">
        <section class="content-header">
          <div class="container-fluid">
            <div class="row">
              <div class="col-sm-6">
                <h5 class="mb-0">Justificaciones</h5>
              </div>
              <div class="col-sm-6">
                <ol class="breadcrumb float-sm-right">
                  <li class="breadcrumb-item">
                    <router-link to="/">Home</router-link>
                  </li>
                  <li class="breadcrumb-item active">Tif</li>
                  <li class="breadcrumb-item active">General</li>
                  <li class="breadcrumb-item active">Operación</li>
                  <li class="breadcrumb-item active">Justificaciones</li>
                </ol>
              </div>
            </div>
          </div>
        </section>
        <section class="content">
          <div class="container-fluid">
            <div class="card">
              <div class="card-header">
                <div class="card-body p-0">
                  <table
                    class="
                      table table-bordered table-striped table-hover table-sm
                      team-roster-table
                      table-responsive
                    "
                  >
                    <thead>
                      <tr>
                        <th>
                          Turno
                          <input
                            type="number"
                            v-model="filtros.turno_id"
                            placeholder="N° Turno"
                            label="id"
                            class="form-control form-control-sm"
                            @keyup.enter="getIndex()"
                          />
                        </th>
                        <th>
                          Bloque
                          <input
                            type="text"
                            v-model="filtros.bloque"
                            placeholder="Bloque"
                            label="id"
                            class="form-control form-control-sm"
                            @input="getIndex()"
                          />
                        </th>
                        <th>
                          Vehículo
                          <input
                            type="text"
                            v-model="filtros.vehiculo_id"
                            placeholder="N° Placa"
                            label="id"
                            class="form-control form-control-sm"
                            @input="getIndex()"
                          />
                        </th>
                        <th>
                          Remolque
                          <input
                            type="text"
                            v-model="filtros.remolque_id"
                            placeholder="N° Placa R"
                            label="id"
                            class="form-control form-control-sm"
                            @input="getIndex()"
                          />
                        </th>
                        <th>
                          Conductor
                          <input
                            type="text"
                            v-model="filtros.conductor_id"
                            placeholder="Nombre"
                            class="form-control form-control-sm"
                            @input="getIndex()"
                          />
                        </th>
                        <th>
                          Horas Turno
                          <select
                            type="text"
                            v-model="filtros.horas_turno"
                            placeholder="Horas Turno"
                            class="form-control form-control-sm"
                            @change="getIndex()"
                          >
                            <option value="">Seleccione...</option>
                            <option
                              v-for="turno in listasForms.turnos"
                              :key="turno.id"
                              :value="turno.id"
                            >
                              {{ turno.nombre }}
                            </option>
                          </select>
                        </th>
                        <th>
                          Operacion
                          <input
                            type="text"
                            v-model="filtros.operacion"
                            placeholder="Operación"
                            class="form-control form-control-sm"
                            @input="getIndex()"
                          />
                        </th>
                        <th>
                          Fecha inicial
                          <input
                            type="date"
                            class="form-control form-control-sm"
                            v-model="filtros.fecha_ini"
                            @change="getIndex()"
                          />
                        </th>
                        <th>
                          Fecha final
                          <input
                            type="date"
                            class="form-control form-control-sm"
                            v-model="filtros.fecha_fin"
                            @change="getIndex()"
                          />
                        </th>
                        <th>Total tiempo (Min)</th>
                        <th>
                          Tipo Novedad
                          <select
                            class="form-control form-control-sm"
                            v-model="filtros.tipo_novedad"
                            @change="getIndex()"
                          >
                            <option value="">Seleccione...</option>
                            <option
                              v-for="novedad in listasForms.tipo_novedades"
                              :key="novedad.numeracion"
                              :value="novedad.numeracion"
                            >
                              {{ novedad.descripcion }}
                            </option>
                          </select>
                        </th>
                        <th>Ubicación</th>
                        <th>
                          Justificado
                          <select
                            type="text"
                            v-model="filtros.justificado"
                            placeholder="Justificado"
                            class="form-control form-control-sm"
                            @change="getIndex()"
                          >
                            <option value="">Seleccione...</option>
                            <option :value="1">Si</option>
                            <option :value="2">No</option>
                          </select>
                        </th>
                        <th>Opciones</th>
                      </tr>
                    </thead>

                    <tbody>
                      <tr v-for="item in novedades.data" :key="item.id">
                        <td>
                          <button
                            type="button"
                            class="btn btn-sm btn-default py-0"
                            data-toggle="modal"
                            data-target="#modal-form-detTurno"
                            @click="llenarModalDetTurno(item.tif_turno_id)"
                          >
                            {{ item.tif_turno_id }}
                          </button>
                        </td>
                        <td>
                          {{ item.tif_turno.bloque.nombre }}
                        </td>
                        <td>
                          {{ item.tif_turno.vehiculo.placa }}
                        </td>

                        <td v-if="item.tif_turno.remolque">
                          {{ item.tif_turno.remolque.placa }}
                        </td>
                        <td v-else></td>
                        <td>
                          {{ item.tif_turno.conductor.nombres }}
                          {{ item.tif_turno.conductor.apellidos }}
                          <span class="badge badge-info">
                            {{ item.tif_turno.conductor.numero_documento }}
                          </span>
                        </td>
                        <td>
                          {{ item.tif_turno.turno.nombre }}
                        </td>
                        <td>
                          {{ item.tif_turno.operacion.Noperacion }}
                        </td>
                        <td>
                          {{ item.fecha_ini }}
                        </td>
                        <td>
                          {{ item.fecha_fin }}
                        </td>
                        <td>{{ item.duracion }}</td>
                        <td>{{ item.tipoNovedad }}</td>
                        <td style="width: 50px" class="text-center">
                          <div class="btn-group">
                            <button
                              type="button"
                              class="btn btn-sm bg-navy"
                              data-toggle="modal"
                              data-target="#modal-form-mapa-justificaciones"
                              style="cursor: pointer"
                              @click="llenarModalMapa(item)"
                            >
                              <i class="fa fa-map-marker-alt"></i>
                            </button>
                          </div>
                        </td>
                        <td class="text-center" v-if="item.justificacion">
                          <span class="badge badge-success">Si</span>
                        </td>
                        <td class="text-center" v-else>
                          <span class="badge badge-danger">No</span>
                        </td>
                        <td style="width: 50px" class="text-left">
                          <div class="btn-group float-left">
                            <button
                              type="button"
                              class="btn btn-sm bg-navy"
                              data-toggle="modal"
                              data-target="#modal-justificaciones"
                              style="cursor: pointer"
                              @click="llenarModal(item)"
                            >
                              <i class="fas fa-edit"></i>
                            </button>
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <div class="card-footer">
                  <div class="float-left" v-if="novedades.total">
                    <p>
                      Mostrando del <b>{{ novedades.from }}</b> al
                      <b>{{ novedades.to }}</b> de un total:
                      <b>{{ novedades.total }}</b> Registros
                    </p>
                  </div>
                  <div class="float-left" v-else>
                    <p>
                      <span class="badge badge-danger">
                        No hay registros para mostrar
                      </span>
                    </p>
                  </div>
                  <pagination
                    :data="novedades"
                    @pagination-change-page="getIndex"
                    :limit="10"
                    align="right"
                  ></pagination>
                </div>
              </div>
            </div>
          </div>
        </section>
        <TifJustificacionesModal ref="TifJustificacionesModal" />
        <TifJustificacionesMapa ref="TifJustificacionesMapa" />
        <TifTurnoDet ref="TifTurnoDet" />
      </div>
    </div>
  </div>
</template>
<script>
import pagination from "laravel-vue-pagination";
import TifJustificacionesModal from "../justificaciones/TifJustificacionesModal";
import TifJustificacionesMapa from "../justificaciones/TifJustificacionesMapa";
import TifTurnoDet from "../turnos/TifTurnoDet";
/* import vSelect from "vue-select"; */
import axios from "axios";
import Loading from "../../../../components/Loading";
export default {
  name: "TifJustificacionesIndex",
  components: {
    Loading,
    pagination,
    /*    vSelect, */
    TifJustificacionesModal,
    TifJustificacionesMapa,
    TifTurnoDet,
  },
  data() {
    return {
      cargando: false,
      novedades: {},
      filtros: {},
      operacion: null,
      conductor: null,
      vehiculo: null,
      remolque: null,
      turno: null,
      listasForms: {
        conductores: [],
        cabezotes: [],
        remolques: [],
        tipo_novedades: [],
        turnos: [],
      },
    };
  },
  methods: {
    getIndex(page = 1) {
      this.cargando = true;
      axios
        .get("/api/tif/justificaciones?page=" + page, {
          params: this.filtros,
        })
        .then((response) => {
          this.novedades = response.data;
          this.cargando = false;
        });
    },

    validaFechas() {
      const fecha_menor = new Date(this.filtros.fecha_inicio);
      const fecha_mayor = new Date(this.filtros.fecha_fin);
      // Se valida que la fecha inicial sea menor que la fecha final
      if (fecha_menor > fecha_mayor) {
        this.filtros.fecha_fin = null;
        this.$swal({
          icon: "error",
          title: `La fecha inicio no puede ser mayor a la fecha fin...`,
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 2000,
          timerProgressBar: true,
        });
      }
    },

    getTurno() {
      axios
        .get("/api/admin/turnos/lista", {
          params: {
            linea_negocio_id: 3,
          },
        })
        .then((response) => {
          this.listasForms.turnos = response.data;
        });
    },

    buscarCabezotes() {
      let me = this;
      var url = "api/admin/vehiculos/lista?estado=1";
      axios
        .get(url)
        .then(function (response) {
          me.listasForms.cabezotes = response.data;
        })
        .catch(function (error) {
          this.$swal({
            icon: "error",
            title: "Ocurrio un error" + error,
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 1000,
            timerProgressBar: true,
          });
        });
    },

    buscarRemolques() {
      let me = this;
      var url = "api/admin/remolques/lista?estado=1";
      axios
        .get(url)
        .then(function (response) {
          me.listasForms.remolques = response.data;
        })
        .catch(function (error) {
          this.$swal({
            icon: "error",
            title: "Ocurrio un error" + error,
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 1000,
            timerProgressBar: true,
          });
        });
    },

    buscarConductores() {
      let me = this;
      var url = "api/admin/conductores/lista?estado=1";
      axios
        .get(url)
        .then(function (response) {
          me.listasForms.conductores = response.data;
        })
        .catch(function (error) {
          this.$swal({
            icon: "error",
            title: "Ocurrio un error" + error,
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 1000,
            timerProgressBar: true,
          });
        });
    },

    seleccionarVehiculo() {
      if (this.vehiculo != null) {
        this.filtros.vehiculo_id = this.vehiculo.id;
      } else {
        this.filtros.vehiculo_id = null;
      }
    },

    seleccionarRemolque() {
      if (this.remolque != null) {
        this.filtros.remolque_id = this.remolque.id;
      } else {
        this.filtros.remolque_id = null;
      }
    },

    seleccionarConductor() {
      if (this.conductor != null) {
        this.filtros.conductor_id = this.conductor.id;
      } else {
        this.filtros.conductor_id = null;
      }
    },

    getSelectTipoOperacion() {
      if (this.operacion != null) {
        this.filtros.operacion_id = this.operacion.id;
      } else {
        this.filtros.operacion_id = null;
      }
    },
    getSelectTurno() {
      if (this.turno != null) {
        this.filtros.turno_id = this.turno.id;
      } else {
        this.filtros.turno_id = null;
      }
    },
    llenarModal(turno) {
      this.$refs.TifJustificacionesModal.llenarModal(turno);
    },

    llenarModalDetTurno(turno) {
      this.$refs.TifTurnoDet.llenar_modal_detTurno(turno);
    },

    getTipoNovedad() {
      let me = this;
      axios.get("/api/lista/105").then((response) => {
        response.data.forEach((novedad) => {
          if (
            novedad.numeracion == 1 ||
            novedad.numeracion == 3 ||
            novedad.numeracion == 5 ||
            novedad.numeracion == 6 ||
            novedad.numeracion == 7
          ) {
            me.listasForms.tipo_novedades.push(novedad);
          }
        });
      });
    },

    llenarModalMapa(item, solicitud_id) {
      this.$refs.TifJustificacionesMapa.limpiar();
      this.$refs.TifJustificacionesMapa.llenar_modal_mapa(item, solicitud_id);
    },
  },

  mounted() {
    this.getIndex();
    this.getTurno();
    this.getTipoNovedad();
    this.buscarCabezotes();
    this.buscarRemolques();
    this.buscarConductores();
  },
};
</script>
